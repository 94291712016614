import { Container } from '@mui/material';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <header>
      <Container fixed sx={{ height: '100%' }}>
        <div className="headerElements">
          <div className="logo">
            <Link to="/">
              <img
                className="logoImg"
                src="logo.png"
                alt="East Coast Handyman"
              />
            </Link>
          </div>
          <nav>
            {/* <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/services">Services</Link>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
            </ul> */}
            <h5>
              Call Today <a href="tel:9026201115">902-620-1115</a>
            </h5>
          </nav>
        </div>
      </Container>
    </header>
  );
};

export default Header;
