import { Container } from '@mui/material';
import { Contact } from './Contact';
import Services from './Services';
export default function Home() {
  return (
    <div className="home">
      <Container fixed>
        <h1>East Coast Handyman</h1>
      </Container>

      <div className="fullWidthHome">
        <Container>
          <h3>Complete Home and Business Service </h3>
        </Container>
      </div>
      <Container>
        <Services />
        <h2>Get an Estimate</h2>
        <Contact />
      </Container>
    </div>
  );
}
