const Services = () => {
  return (
    <>
      <h2 className="servicesHeading">Services</h2>
      <div className="services">
        <div className="serviceContainer">
          <h4>Residential Services</h4>
          <ul>
            <li>Property Cleaning</li>
            <li>Pressure Washing</li>
            <li>Lawn Care Services</li>
            <li>Flooring</li>
            <li>Roofing</li>
            <li>Carpentry</li>
            <li>General Repairs</li>
            <li>Furniture Assembly</li>
            <li>Plumbing</li>
            <li>Painting</li>
          </ul>
        </div>
        <div className="serviceContainer">
          <h4>Commercial Services</h4>
          <ul>
            <li>Property Management</li>
            <li>Lawn Care</li>
            <li>Office Furniture Assembly</li>
            <li>Light Bulb Replacement</li>
            <li>Painting</li>
            <li>Fixture Repairs</li>
          </ul>
        </div>
        <div className="serviceContainer">
          <h4>Junk Removal</h4>
          <ul>
            <li>Construction Debris</li>
            <li>Household Cleanup</li>
            <li>Property Cleanup</li>
            <li>Bush Hogging</li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Services;
