import React, { useRef, useState } from "react";
import emailjs from "emailjs-com";
import { TextField, Grid } from "@mui/material";

import LoadingButton from "@mui/lab/LoadingButton";
export function Contact() {
  const form = useRef();
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    setSending(true);
    emailjs
      .sendForm(
        process.env.REACT_APP_EMAIL_SERVICE,
        process.env.REACT_APP_EMAIL_TEMPLATE,
        form.current,
        process.env.REACT_APP_EMAIL_API
      )
      .then(
        (result) => {
          setSending(false);
          setSent(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  let buttonMessage = "Send";
  if (sent) {
    buttonMessage = "Sent";
  }
  return (
    <div className="mainContainer">
      <div className="contact">
        <div className="form">
          <h4>Send Us a Message</h4>
          <form ref={form} onSubmit={handleSubmit}>
            <Grid container direction={"column"} spacing={3}>
              <Grid item>
                <TextField
                  inputProps={{ style: { width: "300px" } }}
                  id="name"
                  label="Name"
                  name="name"
                  variant="outlined"
                />
              </Grid>

              <Grid item>
                <TextField
                  inputProps={{ style: { width: "300px" } }}
                  id="city"
                  label="City"
                  name="city"
                  variant="outlined"
                />
              </Grid>

              <Grid item>
                <TextField
                  inputProps={{ style: { width: "300px" } }}
                  id="email"
                  label="Email"
                  name="email"
                  variant="outlined"
                />
              </Grid>

              <Grid item>
                <TextField
                  inputProps={{ style: { width: "300px" } }}
                  id="phone"
                  label="Phone"
                  name="phone"
                  variant="outlined"
                />
              </Grid>

              <Grid item>
                <TextField
                  id="message"
                  label="Message"
                  inputProps={{ style: { width: "300px" } }}
                  variant="outlined"
                  name="message"
                  rows={3}
                  maxRows={10}
                  multiline
                />
              </Grid>

              <Grid item>
                <LoadingButton
                  loading={sending}
                  type="submit"
                  variant="outlined"
                  disabled={sent}
                >
                  {buttonMessage}
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </div>

        <div className="snail">
          <h4>Call or Text Us</h4>
          <div>
            <span className="person">John</span>
            <a href="tel:1902620115">
              <span className="phone">902-620-1115</span>
            </a>
          </div>
          <div>
            <span className="person">Trudy</span>
            <a href="tel:19023930027">
              <span className="phone">902-393-0027</span>
            </a>
          </div>
          <img src={process.env.PUBLIC_URL + "/planning.jpg"} alt="planning" />
        </div>
      </div>
    </div>
  );
}
